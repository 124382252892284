<template>
  <div>
    <heads ref="isWxApplet" :backs="1" :msg="title_msg" :states="1" :address_management="1"></heads>
    <div class="borders"></div>
    <van-list
      v-show="isShow"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="(item, index) in list" :key="index" class="item_box" :title="item">
        <div class="main_left">
          <img v-if="item.product_thumb" class="kd_img" :src="item.product_thumb" onerror="javascript:this.src='https://newoss.zhulong.com/forum/202403/05/25/183325dsqrs53owrxld2lg.png'" alt>
          <img v-else class="kd_img" src="https://newoss.zhulong.com/forum/202403/05/25/183325dsqrs53owrxld2lg.png" alt>
        </div>
        <div class="main_right">
          <p class="trade_name">{{ item.product_name }}</p>
          <p class="trade_num">共{{ item.quantity }}件商品 {{ item.express_name?item.express_name:'未知快递' }}</p>
          <div class="btns_div">
            <button v-if="item.express_status != 0" class="see_wl" @click="wl_detail(item.id)">查看物流</button>
            <button v-if="item.express_status == 1" class="confirm_receipt" @click="confirm_receipts(item)">确认收货</button>
          </div>
        </div>
      </div>
    </van-list>
    <div v-show="!isShow" class="kongzt">
      <img src="../../static/images/my_index/kongzt.png" alt>
      <p>您还没有快递哦~</p>
    </div>
    <div v-if="buttonShow">
      <div class="div_empty"></div>
      <van-button round type="danger" class="btn_submit" block @click="go_address_management()">地址管理</van-button>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { express_list, confirm_receipt } from '@/api/my_user.js'
export default {
  components: {
    heads
  },
  data() {
    return {
      title_msg: '快递',
      list: [],
      loading: false,
      finished: false,
      page: 1,
      total: 0,
      retlen: 0,
      limit: 20,
      isShow: true,
      buttonShow: false // 微信小程序打开,下方显示地址管理按钮
    }
  },
  mounted() {
    if (this.$refs.isWxApplet.wx === true) {
      this.buttonShow = true
    }
    // this.onLoad()
    //   this.get_list()
  },
  destroyed() {
    this.list = []
    this.page = 1
    this.total = 0
  },
  methods: {
    wl_detail(id) {
      this.$router.push({
        path: '/express_detail',
        query: {
          id: id
        }
      })
    },
    confirm_receipts(val) {
      var params = {
        id: val.id
      }
      confirm_receipt(params).then(res => {
        console.log(res)
        if (res.errNo === 0) {
          this.page = 1
          this.list = []
          this.onLoad()
          this.$toast({
            message: '确认收货成功',
            type: 'success'
          })
        } else {
          this.$toast({
            message: res.msg || '确认收货失败',
            type: 'fail'
          })
        }
      })
    },
    // 地址管理跳转地址
    go_address_management() {
      // 快递》》》》地址管理
      this.$router.push({ path: '/index/express/address_management', query: { backs: 1 }})
    },
    onLoad() {
      var params = {
        page: this.page,
        limit: this.limit
      }
      express_list(params).then((res) => {
        if (!res.result.list.length && !this.list.length) {
          this.isShow = false
          return false
        }
        this.list = this.list.concat(res.result.list)
        this.total = res.result.count // 数据总条数
        this.retlen = res.result.list.length // 本次返回数据的长度
        if (this.retlen < this.limit) {
          console.log(321)
          this.finished = true // 数据加载完毕显示 没有更多了
          this.isShow = true
        }
        this.page++
        this.loading = false
        console.log(this.list)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .kongzt {
    width: 100%;
    img {
      width: 310px;
      height: 310px;
      margin: 0 auto;
      display: block;
      margin-top: 300px;
    }
    p {
      font-size: 30px;
      font-weight: 600;
      color: #999;
      text-align: center;
      padding-top: 30px;
    }
  }

.express_no{
  color: #999;
  position: absolute;
  left: 224px;
  bottom: 18px;
}

.borders {
  background: #e5e5e5;
  width: 100%;
  height: 1px;
}
.item_box {
  width: calc(100% - 40px);
  border-bottom: 4px solid #f7f7f7;
  padding: 20px 20px;
  display: flex;
  justify-content: start;
}
.main_left {
  width: 160px;
  .kd_img {
    width: 160px;
  }
}
.main_right {
  flex: 1;
  margin-left: 20px;
}
.trade_name {
  font-size: 30px;
  color: #333;
  text-align: start;
  padding-top: 12px;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  overflow : hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.trade_num {
  margin-top: 20px;
  color: #999;
  text-align: right;
}

.btns_div {
  margin-top: 20px;
  text-align: right;
  .see_wl {
    width: 147px;
    height: 46px;
    background: #fff;
    color: #999;
    border: 2px solid #aaa;
    border-radius: 30px;
  }
  .confirm_receipt {
    margin-left: 20px;
    width: 147px;
    height: 46px;
    color: #ee2e2e;
    background: #fff;
    border: 3px solid #ee2e2e;
    border-radius: 30px;
  }
}

.div_empty {
  height: 200px;
}
.btn_submit {
  width: 80%;
  background: #ff272c;
  position: fixed;
  left: 50%;
  margin-left: -40%;
  margin-bottom: 40px;
  bottom:0;
}
</style>
